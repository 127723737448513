<template>
  <div>
    <vx-card>
      <div class="mb-2 md:flex" >
          <h2 class="mb-0">Email Template List</h2>
          <div class="vx-col md:w-1/4 w-full md:ml-auto" align="left">
            <vs-input class="w-full search-input" v-model="searchQuery" placeholder="Search..."/>
          </div>
      </div>

      <div id="data-list-list-view" class="data-list-container">
        <vs-table
          ref="table"
          :sst="true"
          :total="totalDocs"
          :data="users"
          @search="handleSearch"
          @change-page="handleChangePage"
          @sort="handleSort"
          :max-items="dataTableParams.limit"
        >
          <div
            slot="header"
            class="flex flex-wrap-reverse flex-grow justify-between mb-5"
          >
            <div style="float: left">
              <vs-select
                placeholder="10"
                vs-multiple
                autocomplete
                v-model="dataTableParams.limit"
                label="display per page "
                class="mr-6"
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in limitOptions"
                  :clearable="false"
                />
              </vs-select>
            </div>
          </div>

          <template slot="thead">
            <vs-th>Template Name</vs-th>
            <vs-th class="action-col justify-center">Action</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].subject">
                <p v-if="data[indextr].subject === ''">Email Subject</p>
                <div v-else>{{ data[indextr].subject | capitalize }}</div>
              </vs-td>

              <vs-td :data="data[indextr]._id" class="text-center">
                <a href="#" @click.stop="editDetailHandler(data[indextr]._id)">
                  <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current"  />
                </a>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <div class="m-2" v-if="users.length > 0">
        <span class="mr-2">
          {{
            dataTableParams.page * dataTableParams.limit -
              (dataTableParams.limit - 1)
          }}
          -
          {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
              ? dataTableParams.page * dataTableParams.limit
              : totalDocs
          }}
          of {{ totalDocs }}
        </span>
          <div class="pagination-div" v-if="serverResponded">
            <paginate
              :page-count="totalPage"
              :click-handler="handleChangePage"
              class="pagination"
              :page-range="9"
              :prevText="'<'"
              :nextText="'>'"
            ></paginate>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
  import vSelect from "vue-select";
  import { mapActions } from "vuex";

  export default {
    components: {
      "v-select": vSelect
    },
    data() {
      return {
        isMounted: false,
        totalDocs: 0,
        currentPage: 1,
        dataTableParams: {
          search: "",
          sort: "createdAt",
          dir: "asc",
          page: 1,
          limit: 5
        },
        serverResponded: false,
        searchQuery: "",
        awaitingSearch: false,
        users: [],
        limitOptions: [
          { text: "5", value: 5 },
          { text: "10", value: 10 },
          { text: "25", value: 25 },
          { text: "50", value: 50 },
          { text: "100", value: 100 }
        ],
      };
    },
    methods: {
      ...mapActions("email", [
        "fetchEmails"
      ]),
      getEmailsList() {
        this.dataTableParams.search = this.searchQuery;
        this.fetchEmails(this.dataTableParams).then(res => {
          this.users = res.data.data.docs;
          this.totalDocs = res.data.data.pagination.total;
          this.page = res.data.data.pagination.page;
        });
        this.serverResponded = true;
      },
      handleSearch(searching) {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getEmailsList();
      },
      handleChangePage(page) {
        this.dataTableParams.page = page;
        this.getEmailsList();
      },
      handleSort(key, active) {
        this.serverResponded = false;
        this.dataTableParams.sort = key;
        this.dataTableParams.dir = active;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getEmailsList();
      },
      editDetailHandler(id) {
        this.$router.push({ name: "email-template-details", params: { id: id } });
      }
    },
    watch: {
      "dataTableParams.page": function(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.dataTableParams.page = newVal;
          this.getEmailsList();
        }
      },
      "dataTableParams.limit": function(newlimit, oldLimit) {
        if (newlimit !== oldLimit) {
          this.serverResponded = false;
          this.dataTableParams.page = 1;
          this.dataTableParams.limit = newlimit;
          this.getEmailsList();
        }
      },
      searchQuery: function (val) {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.getEmailsList();
            this.awaitingSearch = false;
          }, 1000); // 1 sec delay
        }
        this.awaitingSearch = true;
      },
    },
    computed: {
      totalPage: function() {
        return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
          ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
          : parseInt(this.totalDocs / this.dataTableParams.limit);
      }
    },
    created() {
      this.getEmailsList();
    }
  };
</script>
